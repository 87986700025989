import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';

interface InstitutionNameProp {
  name?: string;
  openDialog: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  dialogTitle: string;
  dialogDescription: React.ReactNode | string;
}

const ConfirmDialog = ({
  name,
  openDialog,
  handleClose,
  handleDelete,
  dialogTitle,
  dialogDescription
}: InstitutionNameProp) => {
  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth>
      <DialogTitle>{dialogTitle}</DialogTitle>

      <DialogContent>
        <Typography>
          {dialogDescription} <strong>{name}</strong> ?
        </Typography>

        <Box
          mt={2}
          width="100%"
          display="flex"
          justifyContent="flex-end"
          gap={2}
        >
          <Button onClick={handleClose}>Otkaži</Button>
          <Button variant="contained" onClick={handleDelete}>
            Potvrdi
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
