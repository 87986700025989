import React, { createContext, useState } from 'react';

import AppToast, { IToastParams } from '../components/base/AppToast';

const ToastContext = createContext<(param: IToastParams) => void>(() => {});

export default ToastContext;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function ToastContextProvider({ children }: any) {
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({} as IToastParams);

  const showToast = (passedParams: IToastParams) => {
    setParams(passedParams);
    setOpen(true);
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <AppToast open={open} setOpen={setOpen} params={params} />
    </ToastContext.Provider>
  );
}
