import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { Form, Formik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import TextInput from '../../components/form/TextInput';
import dobrotaLeci from '../../assets/images/dobrota-leci.png';
import AuthLayout from '../../components/layout/AuthLayout';
import { useAuth } from '../../hooks/useAuth';

const initialValues = {
  newPassword: '',
  confirmNewPassword: ''
};
const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .required('Ovo polje je obavezno!')
    .min(8, 'Lozinka mora biti minimum 8 karaktera')
    .max(255, 'Lozinka može biti maksimalno 255 karaktera'),

  confirmNewPassword: Yup.string()
    .required('')
    .oneOf([Yup.ref('newPassword'), null], 'Lozinke se ne poklapaju')
});

function ResetPassword() {
  const { resetPassword } = useAuth();
  const history = useHistory();
  const pwToken = () => localStorage.getItem('pwToken') || '';
  const [page, setPage] = useState(1);
  const [errorMsg, setErrorMsg] = useState('');
  const {
    mutate: passwordResetMutation,
    isLoading,
    data
  } = useMutation((password: string) => resetPassword(password, pwToken()));
  const handleSubmit = (values: {
    newPassword: string;
    confirmNewPassword: string;
  }) => {
    passwordResetMutation(values.newPassword, {
      onError: () => {
        setErrorMsg('Error occured');
      },
      onSuccess: () => {
        setPage(page + 1);
        localStorage.removeItem('pwToken');
      }
    });
  };
  useEffect(() => {
    if (localStorage.getItem('pwToken') === null) {
      history.push('/login');
    }
  }, [history]);
  return (
    <AuthLayout>
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        <img src={dobrotaLeci} alt="logo" style={{ width: '200px' }} />
      </Box>

      <Box
        my={8}
        mx={4}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {page === 1 ? (
          <>
            {' '}
            <Typography
              component="h1"
              variant="h5"
              align="left"
              sx={{ alignSelf: 'flex-start' }}
            >
              Resetujte lozinku
            </Typography>
            <Box component="div" sx={{ mt: 1 }}>
              <Formik
                initialValues={initialValues}
                validationSchema={resetPasswordSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <TextInput
                      type="password"
                      margin="normal"
                      fullWidth
                      id="newPassword"
                      label="Nova lozinka"
                      name="newPassword"
                    />
                    <TextInput
                      type="password"
                      margin="normal"
                      fullWidth
                      id="confirmNewPassword"
                      label="Potvrdite lozinku"
                      name="confirmNewPassword"
                    />
                    <LoadingButton
                      loading={isLoading}
                      disabled={dirty && !isValid}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Reset
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
            </Box>
          </>
        ) : (
          <Typography
            component="h1"
            variant="h5"
            align="left"
            sx={{ alignSelf: 'flex-start' }}
          >
            {data === 'MOBILE' ? (
              `Uspešno ste resetovali lozinku.`
            ) : (
              <>
                Uspešno ste resetovali lozinku. Sada se možete ulogovati sa{' '}
                <Link to="/login">novom lozinkom</Link>{' '}
              </>
            )}
          </Typography>
        )}
      </Box>
    </AuthLayout>
  );
}

export default ResetPassword;
