import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useField } from 'formik';

const TextInput = ({ name, ...otherProps }: TextFieldProps) => {
  const [field, meta] = useField(name as string);

  const configTextfield: TextFieldProps = {
    ...field,
    ...otherProps,
    fullWidth: true,
    color: 'secondary',
    variant: 'outlined'
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return <TextField {...configTextfield} />;
};

export default TextInput;
