import React, { ChangeEvent, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Alert } from '@mui/material';

import { LoginResponseDTO } from '../../interfaces/loginResponseDTO';
import dobrotaLeci from '../../assets/images/dobrota-leci.png';
import AuthLayout from '../../components/layout/AuthLayout';
import { useAuth } from '../../hooks/useAuth';
import { UserJwtResponseDTO } from '../../interfaces/users/userJwtResponseDTO';
import parseJwt from '../../utils/parseJwt';
import useToastContext from '../../hooks/useToastContext';

export default function SignInSide() {
  const history = useHistory();
  localStorage.clear();
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [loginError, setLoginError] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const [showToast] = useToastContext();

  const { isLoading, error, data } = useQuery<LoginResponseDTO>(
    ['login', submitted],
    () => login(email, password),
    {
      keepPreviousData: true,
      enabled: submitted
    }
  );

  useEffect(() => {
    if (error) {
      setSubmitted(false);
      setLoginError(String(error));
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const userJwtResponse: UserJwtResponseDTO = parseJwt(data.accessToken);
      if (userJwtResponse.role === 'DONOR') {
        setSubmitted(false);
        showToast({
          text: 'Korsnik nije validan, pokušajte opet!',
          severity: 'error'
        });
        return;
      }
      localStorage.setItem('token', data.accessToken);
      history.replace(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitted(true);
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  return (
    <AuthLayout>
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        <img src={dobrotaLeci} alt="logo" style={{ width: '200px' }} />
      </Box>
      <Box
        my={8}
        mx={4}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          component="h1"
          variant="h5"
          align="left"
          sx={{ alignSelf: 'flex-start' }}
        >
          Login
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Lozinka"
            value={password}
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePasswordChange}
          />
          {/* {loginError && (
              <Typography sx={{ color: 'rgb(211, 47, 47)' }}>
                Došlo je do greške. Pokušajte opet kasnije!
              </Typography>
            )} */}
          <LoadingButton
            loading={isLoading}
            disabled={!email || !password || isLoading}
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            sx={{ mt: 3, mb: 2 }}
          >
            Uloguj se
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link href="/forgot-password" variant="body2">
                Zaboravio/la sam lozinku
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {loginError && (
        <Alert
          onClose={() => setLoginError('')}
          sx={{
            position: 'absolute',
            top: 2 * 8,
            left: { xs: 8, sm: 2 * 8, md: 5 * 8, lg: 12 * 8, xl: 20 * 8 },
            right: { xs: 8, sm: 2 * 8, md: 5 * 8, lg: 12 * 8, xl: 20 * 8 }
          }}
          severity="error"
        >
          Došlo je do greške. Pokušajte opet kasnije!
        </Alert>
      )}
    </AuthLayout>
  );
}
