import { createContext, useContext, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

import { UserDTO } from '../interfaces/users/userDTO';
import { LoginResponseDTO } from '../interfaces/loginResponseDTO';
import { BACKEND_BASE_URL } from '../utils/config';
import parseJwt from '../utils/parseJwt';
import { UserJwtResponseDTO } from '../interfaces/users/userJwtResponseDTO';

const authApi = axios.create({
  baseURL: BACKEND_BASE_URL
});
export interface IResetPassword {
  accessToken: string;
}

type ResetPasswordResponse = 'MOBILE' | 'WEB';

interface IAuthContext {
  user?: UserDTO;
  login: (
    email: string,
    password: string
  ) => LoginResponseDTO | Promise<LoginResponseDTO>;
  logout: () => void;
  getCurrentUser: () => void;
  sendPasswordResetEmail: (email: string) => void;
  resetPasswordActivation: (code: string) => Promise<IResetPassword>;
  resetPassword: (
    password: string,
    code: string
  ) => Promise<ResetPasswordResponse>;
}

const authContext = createContext({} as IAuthContext);

const useProvideAuth = () => {
  const [user, setUser] = useState<UserDTO | undefined>({} as UserDTO);

  const sendPasswordResetEmail = async (email: string) => {
    const response = await authApi.put(`/user/password-reset-code`, { email });
    return response.data;
  };

  const resetPasswordActivation = async (
    code: string
  ): Promise<IResetPassword> => {
    const response = await authApi.post(
      `user/validate-password-reset-code/${code}`
    );

    return response.data;
  };

  const getCurrentUser = async () => {
    const jwtToken = localStorage.getItem('token');
    const res: AxiosResponse<UserDTO> = await authApi.get('/user/me', {
      headers: {
        Authorization: `Bearer ${jwtToken}`
      }
    });
    setUser(res.data);
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  const login = async (email: string, password: string) => {
    const response: AxiosResponse<LoginResponseDTO> = await authApi.post(
      `/user/auth`,
      {
        email,
        password
      }
    );
    const { role }: UserJwtResponseDTO = parseJwt(response.data.accessToken);
    if (role !== 'DONOR') {
      localStorage.setItem('token', response.data.accessToken);
      getCurrentUser();
    }
    return response.data;
  };

  const resetPassword = async (password: string, code: string) => {
    const response = await authApi.put(
      `user/password-reset`,
      { password },
      { headers: { Authorization: `Bearer ${code}` } }
    );

    return response.data;
  };

  useEffect(() => {
    if (window.location.pathname !== '/login') getCurrentUser();
  }, []);

  return {
    user,
    login,
    logout,
    getCurrentUser,
    sendPasswordResetEmail,
    resetPasswordActivation,
    resetPassword
  };
};
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }: { children: JSX.Element }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
