import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import InstitutionsList from '../../components/institutions/InstitutionsList';
import AddButton from '../../components/institutions/AddButton';
import InstitutionsDashboard from '../../components/institutions/InstitutionsDashboard';
import AddEditInstitutionForm from '../../components/dialogs/AddEditInstitutionForm';
import useInstitutions from '../../hooks/useInstitutions';
import useInstitution from '../../hooks/useInstitution';
import Acl from '../../components/base/Acl';
import { Role } from '../../interfaces/users/userResponseDto';

const drawerWidth = 481;

const Institutions = () => {
  const [open, setOpen] = useState(false);
  const [institutionId, setInstitutionId] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const addPermissions: Array<Role> = ['SUPER_ADMIN'];

  const {
    isLoading,
    isSuccess,
    data: institutionsData
  } = useInstitutions({
    keepPreviousData: true,
    refetchOnWindowFocus: true
  });

  useEffect(() => {
    if (institutionsData?.length === 0) return;
    if (institutionsData) {
      setInstitutionId(institutionsData[0].id);
    }
  }, [institutionsData]);

  const { data: institutionData } = useInstitution(institutionId, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    enabled: institutionId !== ''
  });

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer
          elevation={3}
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto', ml: '5px' }}>
            <br />
            <InstitutionsList
              institutionsData={institutionsData}
              setInstitutionId={setInstitutionId}
              institutionId={institutionId}
              isLoading={isLoading}
              isSuccess={isSuccess}
            />
            <Acl userRole={addPermissions}>
              <div>
                <AddButton
                  buttonText="Dodaj novu instituciju"
                  onClick={handleOpen}
                />
                <AddEditInstitutionForm
                  open={open}
                  handleClose={handleClose}
                  setInstitutionId={setInstitutionId}
                  type="add"
                />
              </div>
            </Acl>
          </Box>
        </Drawer>
        {institutionId && institutionData ? (
          <InstitutionsDashboard
            institution={institutionData}
            setInstitutionId={setInstitutionId}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Toolbar />
            <Typography variant="h1" align="center">
              Izaberite instituciju
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Institutions;
