import { BLOOD_OPTIONS } from './constants';

// noinspection JSUnusedGlobalSymbols
export const parseBloodGroup = (bloodGroup: string) => {
  if (bloodGroup.includes('+')) {
    return { bloodType: bloodGroup.replace('+', ''), rhFactor: 'POSITIVE' };
  }
  return { bloodType: bloodGroup.replace('-', ''), rhFactor: 'NEGATIVE' };
};

export const findBloodGroupId = (
  bloodType: string | undefined,
  rhFactor: string | undefined
) => {
  if (!bloodType || !rhFactor) return null;
  return (
    BLOOD_OPTIONS.find((el) => {
      return el.text === `${bloodType}${rhFactor === 'POSITIVE' ? '+' : '-'}`;
    })?.id || 0
  );
};

export const parsePhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return null;
  // handle leading with country code
  if (phoneNumber.charAt(0) === '+') {
    return phoneNumber.replace('+381', '');
  }
  // handle leading with 0
  if (phoneNumber.charAt(0) === '0') {
    return phoneNumber.substring(1);
  }
  return phoneNumber;
};

export const toIsoString = (date: Date) => {
  const pad = (num: number) => (num < 10 ? '0' : '') + num;

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds()
  )}`;
};
