import { Redirect, Route } from 'react-router';
import parseJwt from '../../utils/parseJwt';

interface IPrivateRouteProps {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [x: string]: any;
}

// function parseJwt(token: string | null) {
//   if (!token) return '';
//   const base64Url = token.split('.')[1];
//   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   const jsonPayload = decodeURIComponent(
//     atob(base64)
//       .split('')
//       .map(function (c) {
//         return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
//       })
//       .join('')
//   );

//   return JSON.parse(jsonPayload);
// }

// Function that checks if user is valid by checking if the decoded jwt object contains sub with email regex
// TODO: make this more secure
const checkIfUserIsValid = () => {
  const token = localStorage.getItem('token');
  const jwtDecode = parseJwt(token);
  let isValidUser = false;
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
  if (jwtDecode && jwtDecode.sub && jwtDecode.sub.match(emailRegex)) {
    isValidUser = true;
  }

  return isValidUser;
};

const PrivateRoute = ({ ...props }: IPrivateRouteProps) => {
  const isValidUser = checkIfUserIsValid();
  if (isValidUser) {
    return <Route {...props} />;
  }
  return <Redirect to="/login" />;
};

export default PrivateRoute;
