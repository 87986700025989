import { ResponseDTO } from '../interfaces/responseDTO';
import api from '../utils/api';
import { UrlParams } from '../utils/constants';

export const getUsers = async (
  searchParams: UrlParams
): Promise<ResponseDTO> => {
  const sp = searchParams.filter
    ? new URLSearchParams(searchParams.filter)
    : new URLSearchParams();
  sp.append('pageNum', (searchParams.page - 1).toString());
  sp.append('pageSize', '50');
  if (searchParams.sortBy) {
    sp.append('sortBy', searchParams.sortBy);
  }
  if (searchParams.orderBy) {
    sp.append('orderBy', searchParams.orderBy);
  }
  if (searchParams.searchTerm) {
    sp.append('searchTerm', searchParams.searchTerm);
  }
  const response = await api.get<ResponseDTO>(`/user?${sp}`);

  return response.data;
};
