import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Autocomplete, MenuItem, Select } from '@mui/material';
import { disableUser, updateUser } from '../../api/users';
import { UserDTO } from '../../interfaces/users/userDTO';
import useToastContext from '../../hooks/useToastContext';
import Acl from '../base/Acl';
import { Role } from '../../interfaces/users/userResponseDto';
import { CITIES, instUserTypes } from '../../utils/constants';
import ConfirmDialog from '../dialogs/ConfirmDialog';

type TableRowPropsType = {
  row: UserDTO;
};

type TableDataType = {
  [key: string]: string | undefined;
  propName: string;
  value: string | undefined;
};

const ApprovedUsersTableRow = ({ row }: TableRowPropsType) => {
  const queryClient = useQueryClient();
  const deletePermissions: Array<Role> = ['SUPER_ADMIN'];
  const [deleteForm, setOpenDeleteForm] = useState(false);
  const openDeleteForm = () => setOpenDeleteForm(true);
  const closeDeleteForm = () => setOpenDeleteForm(false);
  const [showToast] = useToastContext();
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [editedRow, setEditedRow] = useState(row);

  useEffect(() => {
    setEditedRow(row);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const tableData = useRef<TableDataType[]>([]);
  const setTableData = () => {
    // control which properties of user you want to show here
    // use actual names of object properties
    const visibleFields = [
      'firstName',
      'lastName',
      'email',
      'city',
      'userMedicalType'
    ];
    tableData.current = visibleFields.map((field) => {
      return {
        propName: field,
        value: editedRow[field]
      };
    });
  };
  setTableData();
  useEffect(() => {
    setTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const editUser = useMutation(() => {
    return updateUser(editedRow);
  });

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const deleteUser = useMutation((arg: string) => {
    return disableUser(`${editedRow.id}`);
  });

  const handleDeletingUser = () => {
    deleteUser.mutate('', {
      onSuccess: () => {
        showToast({
          text: 'Korisnik uspješno izbrisan.',
          severity: 'success'
        });
        closeDeleteForm();
        queryClient.invalidateQueries(['institution']);
      }
    });
  };

  const beginEditRow = () => {
    setIsBeingEdited(true);
  };

  const closeEditRow = () => {
    editUser.mutate(undefined, {
      onSuccess: () => {
        showToast({
          text: 'Korisnik uspešno izmenjen.',
          severity: 'success'
        });
      }
    });
    setIsBeingEdited(false);
  };

  const handleOnChange = (propName: string, value: string) => {
    (editedRow as UserDTO)[propName] = value;
    setEditedRow(editedRow);
  };

  const renderInputs = () => {
    return tableData.current.map((input) => {
      if (input.propName === 'city') {
        return (
          <TableCell key={input.propName}>
            <Autocomplete
              defaultValue={row[input.propName]}
              sx={{ width: 200 }}
              disablePortal
              options={CITIES}
              renderInput={(params) => <TextField {...params} label="Grad" />}
            />
          </TableCell>
        );
      }
      if (input.propName === 'userMedicalType') {
        return (
          <TableCell key={input.propName}>
            <Select
              placeholder="Titula"
              defaultValue={row[input.propName]}
              onChange={(e) => {
                handleOnChange(
                  input.propName,
                  (e.target as HTMLInputElement).value
                );
              }}
            >
              {Object.keys(instUserTypes).map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </TableCell>
        );
      }
      return (
        <TableCell key={input.propName}>
          <TextField
            variant="standard"
            autoFocus
            defaultValue={row[input.propName]}
            onKeyUp={(e) => {
              handleOnChange(
                input.propName,
                (e.target as HTMLInputElement).value
              );
            }}
          />
        </TableCell>
      );
    });
  };

  const renderTableCells = () => {
    return tableData.current.map((cell) => (
      <TableCell key={cell.propName}>{row[cell.propName]}</TableCell>
    ));
  };

  return isBeingEdited ? (
    <TableRow
      key={row.nameAndLastName}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          editUser.mutate();
        }
      }}
    >
      {renderInputs()}
      <TableCell align="center">
        <Button
          onClick={() => {
            closeEditRow();
          }}
        >
          <CheckIcon sx={{ color: '#7ed6d2' }} />
        </Button>
      </TableCell>
      <Acl userRole={deletePermissions}>
        <TableCell align="center">
          <Button onClick={() => openDeleteForm()}>
            <DeleteIcon sx={{ color: 'red' }} />
          </Button>
        </TableCell>
      </Acl>
    </TableRow>
  ) : (
    <TableRow
      key={row.nameAndLastName}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      {renderTableCells()}
      <TableCell align="center">
        <Button
          onClick={() => {
            beginEditRow();
          }}
        >
          <EditIcon sx={{ color: '#7ed6d2' }} />
        </Button>
      </TableCell>
      <Acl userRole={deletePermissions}>
        <TableCell align="center">
          <Button onClick={() => openDeleteForm()}>
            <DeleteIcon sx={{ color: 'red' }} />
          </Button>
        </TableCell>
      </Acl>
      <ConfirmDialog
        dialogTitle="Brisanje Korisnika"
        dialogDescription="Da li ste sigurni da želite da izbrišete korisnika"
        name={`${row.firstName} ${row.lastName}`}
        openDialog={deleteForm}
        handleClose={closeDeleteForm}
        handleDelete={handleDeletingUser}
      />
    </TableRow>
  );
};

export default ApprovedUsersTableRow;
