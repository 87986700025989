import { InstitutionDTO } from '../interfaces/intitutions/institutionDTO';
import api from '../utils/api';
import { InstitutionResponseDto } from '../interfaces/intitutions/institutionResponseDto';

export const getInstitutions = async (): Promise<InstitutionResponseDto[]> => {
  const response = await api.get(`/institution`);
  if (response) {
    return response.data;
  }
  throw new Error("Can't get Institution");
};

export const postInstitution = async (
  institution: InstitutionDTO
): Promise<InstitutionResponseDto> => {
  const response = await api.post(`/institution`, institution);
  if (response) {
    return response.data;
  }
  throw new Error("Can't create Institution");
};

export const updateInstitution = async (
  institution: InstitutionDTO
): Promise<InstitutionResponseDto> => {
  const response = await api.put(`/institution/${institution.id}`, institution);
  if (response) {
    return response.data;
  }
  throw new Error("Can't update Institution");
};

export const deleteInstitution = async (
  institutionId: string
): Promise<void> => {
  const response = await api.delete<void>(`/institution/${institutionId}`);
  if (response) {
    return;
  }
  throw new Error("Can't delete Institution");
};

export const getInstitution = async (
  institutionId: string
): Promise<InstitutionResponseDto> => {
  const response = await api.get<InstitutionResponseDto>(
    `/institution/${institutionId}`
  );
  if (response) {
    return response.data;
  }
  throw new Error("Can't delete Institution");
};
