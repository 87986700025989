import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import TextInput from '../form/TextInput';
import SelectCityFormik from '../form/SelectCityFormik';
import { postInstitution, updateInstitution } from '../../api/institutions';
import { InstitutionDTO } from '../../interfaces/intitutions/institutionDTO';
import { InstitutionResponseDto } from '../../interfaces/intitutions/institutionResponseDto';
import useToastContext from '../../hooks/useToastContext';

interface AddInstitutionFormProps {
  open: boolean;
  handleClose: () => void;
  setInstitutionId: (institutionId: string) => void;
  type: 'add' | 'edit';
  institutionProp?: InstitutionDTO;
}

const phoneRegExp = /^[6][0-9]{7,8}$/;

const formValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Ime mora da sadrži minimum 2 karaktera')
    .required('Obavezno'),
  city: Yup.string()
    .min(2, 'Ime mora da sadrži minimum 2 karaktera')
    .required('Obavezno'),
  address: Yup.string()
    .min(2, 'Ime mora da sadrži minimum 2 karaktera')
    .required('Obavezno'),
  numberOfDevices: Yup.number().min(1).required('Obavezno'),
  phone: Yup.string().matches(
    phoneRegExp,
    'Broj telefona mora da počne sa 6 i da sadrži 7 ili 8 cifara'
  )
});

const AddEditInstitutionForm = ({
  open,
  handleClose,
  setInstitutionId,
  type,
  institutionProp
}: AddInstitutionFormProps) => {
  const queryClient = useQueryClient();

  const addInstitution: UseMutationResult<
    InstitutionResponseDto,
    Error,
    InstitutionDTO
  > = useMutation<InstitutionResponseDto, Error, InstitutionDTO>(
    async (institution) => postInstitution(institution)
  );
  const editInstitution: UseMutationResult<
    InstitutionResponseDto,
    Error,
    InstitutionDTO
  > = useMutation<InstitutionResponseDto, Error, InstitutionDTO>(
    async (institution) => updateInstitution(institution)
  );

  const [showToast] = useToastContext();

  const initialValues =
    type === 'add'
      ? {
          name: '',
          city: '',
          address: '',
          numberOfDevices: 0,
          telephoneNumber: ''
        }
      : { ...institutionProp };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ square: true }}
        fullWidth
      >
        <DialogTitle>
          {type === 'edit' ? 'Izmeni' : 'Dodaj'} Instituciju
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ ...initialValues }}
            validationSchema={formValidation}
            onSubmit={(values) => {
              const institutionObj =
                values as unknown as InstitutionResponseDto;
              if (type === 'add') {
                addInstitution.mutate(institutionObj, {
                  onError: () => {
                    showToast({
                      text: 'Došlo je do greške. Pokušajte opet kasnije!',
                      severity: 'error'
                    });
                  },
                  onSuccess: () => {
                    queryClient.invalidateQueries('institutions').then();
                    queryClient.invalidateQueries('institutions').then(() => {
                      if (institutionProp !== undefined) {
                        setInstitutionId('');
                        setInstitutionId(institutionProp?.id);
                      }
                    });
                    showToast({
                      text: 'Institucija uspešno dodata.',
                      severity: 'success'
                    });
                  }
                });
              } else {
                editInstitution.mutate(institutionObj, {
                  onError: () => {
                    showToast({
                      text: 'Došlo je do greške. Pokušajte opet kasnije!',
                      severity: 'error'
                    });
                  },
                  onSuccess: () => {
                    queryClient
                      .invalidateQueries(['institution', institutionProp?.id])
                      .then();

                    queryClient.invalidateQueries('institutions').then(() => {
                      if (institutionProp !== undefined) {
                        setInstitutionId('');
                        setInstitutionId(institutionProp?.id);
                      }
                    });

                    showToast({
                      text: 'Institucija uspešno izmenjena.',
                      severity: 'success'
                    });
                  }
                });
              }
              handleClose();
            }}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <TextInput
                      name="name"
                      type="text"
                      label="Ime Institucije"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectCityFormik
                      name="city"
                      label="Mesto prebivališta"
                      city={institutionProp?.city}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextInput name="address" label="Adresa" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextInput name="telephoneNumber" label="Telefonski broj" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextInput name="numberOfDevices" label="Broj separatora" />
                  </Grid>
                  <Box
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    gap={2}
                  >
                    <Button onClick={handleClose}>Otkaži</Button>
                    <Button
                      variant="contained"
                      disabled={!(dirty && isValid)}
                      type="submit"
                    >
                      Potvrdi
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddEditInstitutionForm;
