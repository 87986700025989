import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import Logo from '../../assets/images/logo.png';
import User from '../../assets/images/user.png';
import { useAuth } from '../../hooks/useAuth';

const LOCATION_MAP = {
  '/': 0,
  '/users': 1,
  '/management': 2
};

interface LinkTabProps {
  label?: string;
  href: string;
}
const LinkTab = (props: LinkTabProps) => {
  const { label, href } = props;
  const history = useHistory();

  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push(href);
      }}
      disableRipple
      disableFocusRipple
      sx={{ mt: 2 }}
      label={label}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const Header = () => {
  const location = useLocation();
  // const history = useHistory();

  const { user, logout } = useAuth();
  const [value, setValue] = useState<number>(
    LOCATION_MAP[location.pathname as '/' | '/users' | '/management'] || 0
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    setAnchorEl(null);
    logout();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const menuId = 'user-details-menu';

  const renderMenu = (
    <Menu
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={signOut}>
        <ListItemIcon>
          <LogoutIcon color="primary" />
        </ListItemIcon>
        Odjavi se
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: 'header.main',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      elevation={0}
    >
      <Toolbar
        disableGutters
        sx={{ display: 'flex', justifyContent: 'space-between', pr: 4 }}
      >
        <Box display="flex" alignItems="center">
          <Box px={2} height="77px" sx={{ backgroundColor: '#FFFFFF' }} mr={2}>
            <img src={Logo} alt="logo" />
          </Box>
          <Box display="flex" height="77px">
            <Tabs value={value} onChange={handleChange}>
              <LinkTab label="Termini" href="/" />
              <LinkTab label="Korisnici" href="/users" />
              <LinkTab label="Upravljanje" href="/management" />
            </Tabs>
          </Box>
        </Box>
        <Box sx={{ '&:hover': { opacity: 0.8 } }}>
          <Box
            height="36px"
            display="flex"
            gap={2}
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={handleProfileMenuOpen}
          >
            <img src={User} alt="user" />
            <Typography sx={{ color: 'textColor.light' }} variant="subtitle2">
              {`${user?.firstName} ${user?.lastName}
              
              `}
            </Typography>
          </Box>
          {renderMenu}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
