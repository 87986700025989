import api from '../utils/api';
import { UserDTO } from '../interfaces/users/userDTO';
import { UserResponseDto } from '../interfaces/users/userResponseDto';

export const postUser = async (user: UserDTO): Promise<UserResponseDto> => {
  const response = await api.post(`/user/`, user);

  if (response) {
    return response.data;
  }
  throw new Error("Can't create user!");
};

export const updateUser = async (user: UserDTO): Promise<UserResponseDto> => {
  const response = await api.put(`/user/${user.id}`, user);

  if (response) return response.data;

  throw new Error("Can't update user!");
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const disableUser = async (userId: string): Promise<any> => {
  const response = await api.delete(`/user/${userId}`);

  if (response) return response.data;

  throw new Error("Can't delete user!");
};

export const searchForDonors = async (
  nameQuery: string,
  bookingDate: string
) => {
  try {
    const response = await api.get(
      `/user/donor?date=${bookingDate}&name=${nameQuery}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
