import { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Container
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { useQuery } from 'react-query';

import AddEditVerifyUser from '../../components/dialogs/AddEditVerifyUser';
import FilterUsersDialog from '../../components/dialogs/FilterUsersDialog';
import UsersTable from './components/UsersTable';
import { getUsers } from '../../api/getUsers';
import { UserDTO } from '../../interfaces/users/userDTO';
import { UrlParams } from '../../utils/constants';
import useDebounce from '../../hooks/useDebounce';

const DEFAULT_PAGE_NUM = 1;

const Users = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDTO | null>(null);
  const [action, setAction] = useState<string>('ADD');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [urlParams, setUrlParams] = useState<UrlParams>({
    page: DEFAULT_PAGE_NUM,
    sortBy: 'FIRST_NAME',
    orderBy: 'ASC',
    filter: '',
    searchTerm
  });
  const [sortBy, setSortBy] = useState();
  const [orderBy, setOrderBy] = useState();
  const { data: userData } = useQuery(
    [
      'userList',
      urlParams.page,
      urlParams.sortBy,
      urlParams.orderBy,
      urlParams.filter,
      urlParams.searchTerm
    ],
    () => getUsers(urlParams),
    {
      keepPreviousData: true
    }
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  useEffect(() => {
    setUrlParams({ ...urlParams, searchTerm: debouncedSearchTerm });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleOpenDialogClick = (act: string, user?: UserDTO) => {
    setAction(act);
    setDialogOpen(true);
    if (user) {
      setSelectedUser(user);
    }
  };

  const handleCloseDialogClick = (): void => {
    setAction('ADD');
    setDialogOpen(false);
    setSelectedUser(null);
  };

  const handleCloseFilterDialog = (params: string) => {
    setUrlParams({ ...urlParams, filter: params, page: DEFAULT_PAGE_NUM });
    setFilterDialogOpen(false);
  };

  const handlePageChange = (newPage: number): void => {
    setUrlParams({ ...urlParams, page: newPage });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(event.target.value);
  return (
    <Container maxWidth="xl" sx={{ pt: 3, backgroundColor: '#FFFFFF' }}>
      <Box mt={9}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialogClick('ADD')}
              sx={{ mr: 2 }}
            >
              Dodaj korisnika
            </Button>
            <Button
              variant="outlined"
              onClick={() => setFilterDialogOpen(true)}
              startIcon={<FilterListIcon />}
            >
              Filteri
            </Button>
          </div>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-search">
              Pretraži
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-search"
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              label="Search"
            />
          </FormControl>
        </Box>
        <AddEditVerifyUser
          open={dialogOpen}
          handleClose={handleCloseDialogClick}
          action={action}
          userObj={selectedUser}
        />
        <FilterUsersDialog
          open={filterDialogOpen}
          handleClose={() => setFilterDialogOpen(false)}
          handleFilterSubmit={handleCloseFilterDialog}
        />
        <UsersTable
          handleDialogOpen={handleOpenDialogClick}
          sortBy={sortBy}
          orderBy={orderBy}
          setSortBy={setSortBy}
          setOrderBy={setOrderBy}
          users={userData?.content.map((item) => item)}
          page={urlParams.page}
          totalPages={userData?.totalPages}
          onPageChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default Users;
