import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Users from './views/Users/Users';
import Layout from './components/layout/Layout';
import SignInSide from './views/Auth/SignInSide';
import ForgotPassword from './views/Auth/ForgotPassword';

import SideBarInstitutions from './views/Institutions/Institutions';
import Slots from './views/Slots/Slots';
import { ToastContextProvider } from './contexts/ToastContext';
import { AuthProvider } from './hooks/useAuth';
import PrivateRoute from './components/base/PrivateRoute';
import ResetPasswordLinkActivation from './views/Auth/ResetPasswordLinkActivation';
import ResetPassword from './views/Auth/ResetPassword';

const queryClient = new QueryClient();
function App() {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [token, setToken] = useState<string | boolean>(
    localStorage.getItem('token') || false
  );
  /* eslint-enable @typescript-eslint/no-unused-vars */

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ToastContextProvider>
          <Router>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                render={(props: any) => (
                  <Layout>
                    <Slots {...props} />
                  </Layout>
                )}
              />
              <PrivateRoute
                path="/users"
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                render={(props: any) => (
                  <Layout>
                    <Users {...props} />
                  </Layout>
                )}
              />

              <PrivateRoute
                path="/management"
                render={() => (
                  <Layout>
                    <SideBarInstitutions />
                  </Layout>
                )}
              />
              <Route
                path="/password-reset"
                render={() => <ResetPasswordLinkActivation />}
              />
              <Route exact path="/login">
                <SignInSide />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route path="/reset-password">
                <ResetPassword />
              </Route>
              <Route path="*">
                <div>404</div>
              </Route>
            </Switch>
          </Router>
        </ToastContextProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
