import { useQuery } from 'react-query';
import { getInstitutions } from '../api/institutions';

const useInstitutions = ({ ...props }) => {
  const queryResults = useQuery('institutions', () => getInstitutions(), {
    ...props
  });

  return { ...queryResults };
};

export default useInstitutions;
