import { IconButton, ListItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { MobileTimePicker } from '@mui/lab';
import { SlotDTO } from '../../interfaces/slotDTO';
import { InstitutionResponseDto } from '../../interfaces/intitutions/institutionResponseDto';
import { deleteInstitutionSlot, editInstitutionSlot } from '../../api/slots';
import { EditSlotAttributeDto } from '../../interfaces/editSlotAttributeDto';
import useToastContext from '../../hooks/useToastContext';
import { dateToHoursAndMinutes } from '../../utils/helpers';
import useDebounce from '../../hooks/useDebounce';
import ConfirmDialog from '../dialogs/ConfirmDialog';

interface ScheduleSlotProps {
  slot: SlotDTO;
  index: number;
  institution: InstitutionResponseDto;
}

const ScheduleSlot = ({ slot, institution }: ScheduleSlotProps) => {
  const currentDate = new Date();
  const hideMask = true;
  const hours = parseInt(slot.time.split(':')[0], 10);
  const minutes = parseInt(slot.time.split(':')[1], 10);
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);

  const [value, setValue] = useState<Date | null>(currentDate);

  const queryClient = useQueryClient();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [showToast] = useToastContext();

  const regex = new RegExp(' /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/');

  const debouncedSearchTerm = useDebounce(value, 500);

  const editTimeSlot = useMutation<
    InstitutionResponseDto,
    Error,
    EditSlotAttributeDto
  >(async (slotDto) => editInstitutionSlot(slotDto));

  const handleChange = (newDate: Date | null) => {
    setValue(newDate);
  };
  const editSlotTime = useCallback(
    (newTime: Date) => {
      editTimeSlot.mutate({
        slotId: slot.id,
        institutionId: institution.id,
        time: { time: dateToHoursAndMinutes(newTime) }
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (debouncedSearchTerm) {
      editSlotTime(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, editSlotTime]);

  interface InstitutionIdSlotId {
    institutionId: string;
    slotId: string;
  }

  const deleteTimeSlot = useMutation<
    InstitutionResponseDto,
    Error,
    InstitutionIdSlotId
  >(async (ids) => deleteInstitutionSlot(ids));

  const deleteScheduledTime = (id: string) => {
    deleteTimeSlot.mutate(
      { institutionId: institution.id, slotId: id },
      {
        onError: () => {
          showToast({
            text: 'Došlo je do greške prilikom brisanja Termina. Pokušajte opet kasnije!',
            severity: 'error'
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries('institution').then();
          showToast({
            text: 'Termin uspešno izbrisan.',
            severity: 'success'
          });
        }
      }
    );
  };

  return (
    <ListItem
      key={slot.id}
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      {
        <>
          <MobileTimePicker
            ampm={false}
            disableMaskedInput={hideMask}
            acceptRegex={regex}
            label="Vreme"
            value={value}
            onChange={handleChange}
            renderInput={(params) => {
              // eslint-disable-next-line react/jsx-props-no-spreading
              return <TextField {...params} />;
            }}
          />
          <IconButton
            edge="end"
            title="Izbriši termin"
            onClick={() => setDialogOpen(true)}
          >
            <CloseIcon />
          </IconButton>
          <ConfirmDialog
            dialogTitle="Brisanje Termina"
            dialogDescription="Da li ste sigurni da želite da izbrišete termin"
            openDialog={dialogOpen}
            handleClose={() => setDialogOpen(false)}
            handleDelete={() => deleteScheduledTime(slot.id)}
          />
        </>
      }
    </ListItem>
  );
};

export default ScheduleSlot;
