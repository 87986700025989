import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';

interface Props {
  name: string;
  label: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  options: any;
}

const SelectUserType: React.FC<Props> = ({ name, options, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name);

  const handleChange = (evt: { target: { value: string } }) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    fullWidth: true,
    error: false,
    helperText: '',
    onChange: handleChange
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField {...configSelect}>
      {Object.keys(options).map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {options[item]}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectUserType;
