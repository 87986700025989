import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ApprovedUsersTableRow from './ApprovedUsersTableRow';
import { UserDTO } from '../../interfaces/users/userDTO';
import Acl from '../base/Acl';
import { Role } from '../../interfaces/users/userResponseDto';

interface ApprovedUsersProps {
  institutionUsers: UserDTO[];
}

export default function ApprovedUsersTable({
  institutionUsers
}: ApprovedUsersProps) {
  const deletePermissions: Array<Role> = ['SUPER_ADMIN'];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ime</TableCell>
            <TableCell>Prezime</TableCell>
            <TableCell>E-mail</TableCell>
            {/* <TableCell>Datum rodjenja</TableCell> */}
            <TableCell>Grad</TableCell>
            <TableCell>Titula</TableCell>
            <TableCell align="center">Izmijeni</TableCell>
            <Acl userRole={deletePermissions}>
              <TableCell align="center">Izbriši</TableCell>
            </Acl>
          </TableRow>
        </TableHead>
        <TableBody>
          {institutionUsers ? (
            institutionUsers.map((row) => (
              <ApprovedUsersTableRow key={row.id} row={row} />
            ))
          ) : (
            <TableRow>
              <TableCell>Nema korisnika</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
