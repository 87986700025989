import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useMutation, UseMutationResult } from 'react-query';
import { Link, Redirect } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { IResetPassword, useAuth } from '../../hooks/useAuth';
import dobrotaLeci from '../../assets/images/dobrota-leci.png';
import AuthLayout from '../../components/layout/AuthLayout';

function ResetPasswordLinkActivation() {
  const [resetPasswordMessage, setResetPasswordMessage] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const resetPasswordCode = queryParams.get('code');
  const { resetPasswordActivation } = useAuth();
  interface IError {
    response: {
      data: {
        status: string;
        message: string;
      };
    };
  }

  const {
    mutate: activateCode,
    data
  }: UseMutationResult<IResetPassword, IError, string> = useMutation<
    IResetPassword,
    IError,
    string
  >((code) => resetPasswordActivation(code));

  if (data?.accessToken) {
    localStorage.setItem('pwToken', data?.accessToken);
  }

  useEffect(() => {
    if (resetPasswordCode) {
      activateCode(resetPasswordCode, {
        onError: (error: {
          response: { data: { message: React.SetStateAction<string> } };
        }) => {
          setResetPasswordMessage(error.response.data.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!resetPasswordCode) {
    return (
      <Redirect
        to="/sign-in
"
      />
    );
  }
  return (
    <AuthLayout>
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        <img src={dobrotaLeci} alt="logo" style={{ width: '200px' }} />
      </Box>
      <Box
        my={8}
        mx={4}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {resetPasswordMessage !== '' ? (
          <Typography
            component="h1"
            variant="h5"
            align="left"
            sx={{ alignSelf: 'flex-start' }}
          >
            Dogodila se greška.{' '}
            <Link to="/forgot-password">Pokušajte ponovo.</Link>
          </Typography>
        ) : (
          <Typography
            component="h1"
            variant="h5"
            align="left"
            sx={{ alignSelf: 'flex-start' }}
          >
            Možete da resetujete svoju{' '}
            <Link to="/reset-password">lozinku.</Link>
          </Typography>
        )}
      </Box>
    </AuthLayout>
  );
}

export default ResetPasswordLinkActivation;
