import { Card, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useState } from 'react';
import styles from './Bookings.module.css';
import BookingItem from './BookingItem';
import {
  ISlotPreview,
  SlotBookingDTO
} from '../../../interfaces/SlotBookingsDTO';

interface IBookingSlotProps {
  slot: SlotBookingDTO;
  numberOfDevices: number;
  openBookingForm: (slot: ISlotPreview) => void;
}

const BookingSlot = ({
  slot,
  numberOfDevices,
  openBookingForm
}: IBookingSlotProps) => {
  const [remainingButtons, setRemainingButtons] = useState<Array<number>>([]);

  const handleClick = useCallback(() => {
    openBookingForm(slot.slot);
  }, [openBookingForm, slot.slot]);

  const renderRemainingButtons = () => {
    const limit = numberOfDevices - slot.bookings.length;

    let tempButtons = [];
    if (slot && slot.bookings && !slot.bookings[0]) {
      // nema bookinga
      tempButtons = Array(numberOfDevices).fill(1);
    } else {
      // ima bookinga
      tempButtons = Array(Math.abs(limit)).fill(1);
    }

    setRemainingButtons(tempButtons);
  };

  useEffect(() => {
    renderRemainingButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfDevices, openBookingForm]);
  const splittedSlotTime = slot.slot.time.split(':');
  const removedZerosFromSlotTime = splittedSlotTime
    .splice(0, splittedSlotTime.length - 1)
    .join(':');

  return (
    <Grid item xs={4} className={styles.gridItem} key={slot.slot.id}>
      <Typography
        sx={{ fontSize: 14, fontWeight: 400 }}
        color="text.secondary"
        gutterBottom
      >
        Termin {removedZerosFromSlotTime}
      </Typography>
      {slot.bookings
        ? slot.bookings.map(
            (booking) =>
              booking && <BookingItem key={booking.id} booking={booking} />
          )
        : 'No bookings'}
      {[...remainingButtons].map((ele, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Card key={index} sx={{ minWidth: 275, marginBottom: 2 }}>
            <LoadingButton
              color="primary"
              loadingPosition="start"
              startIcon={<AddIcon />}
              variant="text"
              onClick={handleClick}
              sx={{
                padding: '1.8rem 1.5rem',
                width: '100%',
                justifyContent: 'left'
              }}
            >
              Zakaži termin
            </LoadingButton>
          </Card>
        );
      })}
    </Grid>
  );
};

export default BookingSlot;
