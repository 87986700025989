import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { sub } from 'date-fns';
import TextInput from '../form/TextInput';
import SelectCityFormik from '../form/SelectCityFormik';
import { postUser } from '../../api/users';
import { UserDTO } from '../../interfaces/users/userDTO';
import useToastContext from '../../hooks/useToastContext';
import SelectUserType from '../../views/Institutions/components/SelectUserType';
import { instUserTypes } from '../../utils/constants';
import DatePicker from '../form/DatePicker';

interface AddInstitutionUserFormProps {
  open: boolean;
  handleClose: () => void;
  institutionId: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  address: string;
  role: string;
  dateOfBirth: Date;
  userMedicalType: string;
  telephoneNumber: string;
  password: string;
  institutionId: string;
}

const phoneRegExp = /^[6][0-9]{7,8}$/;

const formValidation = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Ime mora da sadrži minimum 2 karaktera')
    .required('Obavezno'),
  lastName: Yup.string()
    .min(2, 'Prezime mora da sadrži minimum 2 karaktera')
    .required('Obavezno'),
  email: Yup.string().email('Nevalidan email format!').required('Obavezno'),
  city: Yup.string()
    .min(2, 'Grad mora da sadrži minimum 2 karaktera')
    .required('Obavezno'),
  address: Yup.string()
    .min(2, 'Ime mora da sadrži minimum 2 karaktera')
    .required('Obavezno'),
  telephoneNumber: Yup.string()
    .matches(
      phoneRegExp,
      'Broj telefona mora da počne sa 6 i da sadrži 7 ili 8 cifara'
    )
    .required('Obavezno'),
  dateOfBirth: Yup.date()
    .max(new Date(Date.now() - 567648000000))
    .required('Obavezno'),
  password: Yup.string()
    .required('Obavezno')
    .min(8, 'Lozinka mora biti minimum 8 karaktera')
    .max(255, 'Lozinka moze biti maksimum 255 karaktera'),
  userMedicalType: Yup.string().required('Obavezno'),
  role: Yup.string(),
  status: Yup.string(),
  note: Yup.string()
});

const AddInstitutionUserForm = ({
  open,
  handleClose,
  institutionId
}: AddInstitutionUserFormProps) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(async (user: UserDTO) => postUser(user));
  const [showToast] = useToastContext();
  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    telephoneNumber: '',
    dateOfBirth: sub(new Date(), { years: 18 }),
    city: '',
    address: '',
    role: 'INSTITUTION_ADMIN',
    userMedicalType: '',
    password: '',
    institutionId
  };

  const COUNTRY_CODE = '+381';

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ square: true }}
      fullWidth
    >
      <DialogTitle>Dodaj Korisnika u Instituciju</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidation}
          onSubmit={(values) => {
            const userObj = values as unknown as UserDTO;
            mutation.mutate(userObj, {
              onError: () => {
                showToast({
                  text: 'Greška prilikom dodavanja korisnika..',
                  severity: 'error'
                });
              },
              onSuccess: () => {
                queryClient.invalidateQueries('institution');
                showToast({
                  text: 'Uspesno ste dodali novog korisnika',
                  severity: 'success'
                });
              }
            });
            handleClose();
          }}
        >
          {({ dirty, isValid }) => (
            <Form>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <TextInput name="firstName" type="text" label="Ime" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput name="lastName" type="text" label="Prezime" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput name="email" type="text" label="Email" />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    name="dateOfBirth"
                    label="Datum rodjenja"
                    maxDate={sub(new Date(), { years: 18 })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name="telephoneNumber"
                    label="Telefonski broj"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {COUNTRY_CODE}
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectCityFormik
                    name="city"
                    label="Mesto prebivališta"
                    city=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput name="address" label="Adresa" />
                </Grid>

                <Grid item xs={12}>
                  <SelectUserType
                    name="userMedicalType"
                    label="Odaberite tip korisnika"
                    options={instUserTypes}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput name="password" label="Lozinka" />
                </Grid>
                <Box
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  gap={2}
                >
                  <Button onClick={handleClose}>Otkaži</Button>
                  <Button
                    variant="contained"
                    disabled={!(dirty && isValid)}
                    type="submit"
                  >
                    Potvrdi
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddInstitutionUserForm;
