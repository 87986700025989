import { useState, useEffect, FC } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Link } from 'react-router-dom';
import useInstitutions from '../../hooks/useInstitutions';
import Bookings from '../../components/slots/Bookings/Bookings';
import styles from './Slots.module.css';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const Slots: FC<RouteComponentProps<any, StaticContext, unknown>> = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [noInstitution, setNoInstitution] = useState(false);
  const [selectedInstitutionId, setSelectedInstitutionId] = useState('');
  const [numberOfDevices, setNumberOfDevices] = useState(0);
  const hideMask = true;
  const {
    // isLoading,
    // isSuccess,
    // error,
    data: institutionsData
  } = useInstitutions({
    keepPreviousData: true,
    refetchOnWindowFocus: true
  });

  const handleChange = (newValue: Date | null) => {
    if (newValue) setSelectedDate(newValue);
  };

  const selectInstitution = () => {
    const selectedInstitution = institutionsData?.find(
      (inst) => inst.id === selectedInstitutionId
    );

    if (selectedInstitution) {
      setNumberOfDevices(selectedInstitution.numberOfDevices);
    }
  };

  const handleInstitutionChange = (e: SelectChangeEvent) => {
    setSelectedInstitutionId(e.target.value);
    selectInstitution();
  };

  useEffect(() => {
    selectInstitution();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionsData, selectedInstitutionId]);
  useEffect(() => {
    if (institutionsData?.length === 0) {
      setNoInstitution(true);
    } else if (institutionsData !== undefined) {
      setSelectedInstitutionId(institutionsData[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionsData]);
  if (noInstitution)
    return (
      <div style={{ padding: '100px 0', backgroundColor: 'transparent' }}>
        Ne postoji nijedna institucija. Molimo Vas{' '}
        <Link to="/management">kreirajte jednu.</Link>
      </div>
    );
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className={styles.slotsContainer}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          className={styles.slotsHeader}
        >
          {/* <Button variant="contained">Podesiti prioritetne krvne grupe</Button> */}
          <DesktopDatePicker
            label="Date desktop"
            inputFormat="yyyy/MM/dd"
            disableMaskedInput={hideMask}
            value={selectedDate}
            onChange={handleChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} />}
          />
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="demo-simple-select-label">Institucija</InputLabel>
            <Select
              MenuProps={{ disableScrollLock: true }}
              labelId="demo-simple-select-label"
              label="Institucija"
              placeholder="Institucija"
              value={selectedInstitutionId}
              onChange={handleInstitutionChange}
            >
              {institutionsData &&
                institutionsData.map((inst) => {
                  return (
                    <MenuItem value={inst.id} key={inst.id}>
                      {inst.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Bookings
        selectedDate={selectedDate}
        institutionId={selectedInstitutionId}
        numberOfDevices={numberOfDevices}
      />
    </LocalizationProvider>
  );
};

export default Slots;
