import { AddSlotAttributeDto } from '../interfaces/addSlotAttributeDto';
import { InstitutionResponseDto } from '../interfaces/intitutions/institutionResponseDto';
import { EditSlotAttributeDto } from '../interfaces/editSlotAttributeDto';
import api from '../utils/api';

export const addSlotToInstitution = async ({
  id,
  time
}: AddSlotAttributeDto): Promise<InstitutionResponseDto> => {
  const response = await api.post<InstitutionResponseDto>(
    `/institution/${id}/slot`,
    time
  );
  if (response) {
    return response.data;
  }
  throw new Error("Can't add Time Slot to Institution");
};

export const editInstitutionSlot = async ({
  institutionId,
  slotId,
  time
}: EditSlotAttributeDto): Promise<InstitutionResponseDto> => {
  const response = await api.put<InstitutionResponseDto>(
    `/institution/${institutionId}/slot/${slotId}`,
    time
  );
  if (response) {
    return response.data;
  }
  throw new Error("Can't edit Time Slot");
};

interface InstitutionIdSlotId {
  institutionId: string;
  slotId: string;
}

export const deleteInstitutionSlot = async ({
  institutionId,
  slotId
}: InstitutionIdSlotId): Promise<InstitutionResponseDto> => {
  const response = await api.delete<InstitutionResponseDto>(
    `/institution/${institutionId}/slot/${slotId}`
  );
  if (response) {
    return response.data;
  }
  throw new Error("Can't edit Time Slot");
};
