import srLocale from 'date-fns/locale/sr-Latn';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useField, useFormikContext } from 'formik';

const MASK = '__.__.____';
const FORMAT_LONG_ARGUMENTS = {
  formats: {
    full: 'EEEE, d. MMMM yyyy',
    long: 'd. MMMM yyyy',
    medium: 'd. MMM yy',
    short: 'dd.MM.yyyy'
  },
  defaultWidth: 'full'
};

interface Formats {
  full: string;
  long: string;
  medium: string;
  short: string;
}

interface FormatLongDate {
  formats: Formats;
  defaultWidth: string;
}

function buildFormatLongFn(args: FormatLongDate) {
  return (options = {} as any) => {
    const width = options.width ? String(options.width) : args.defaultWidth;
    const format =
      args.formats[width as keyof Formats] ||
      args.formats[args.defaultWidth as keyof Formats];
    return format;
  };
}

const DatePickerWrapper = ({
  name,
  label,
  ...otherProps
}: {
  name: string;
  label: string;
  maxDate: Date;
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name as string);

  const handleChange = (date: unknown) => {
    setFieldValue(name as string, date);
  };

  const configTextField: TextFieldProps = {
    color: 'secondary',
    variant: 'outlined',
    fullWidth: true
  };

  const renderInput = (params: TextFieldProps) => (
    <TextField {...params} {...configTextField} required />
  );

  const configDatePicker: DatePickerProps = {
    ...field,
    ...otherProps,
    onChange: handleChange,
    renderInput,
    label,
    mask: MASK
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={{
        ...srLocale,
        formatLong: {
          ...srLocale.formatLong,
          date: buildFormatLongFn(FORMAT_LONG_ARGUMENTS)
        }
      }}
    >
      <DatePicker {...configDatePicker} />
    </LocalizationProvider>
  );
};

export default DatePickerWrapper;
