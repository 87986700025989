import TextField, { TextFieldProps } from '@mui/material/TextField';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent } from 'react';

type SelectWrapperProps = TextFieldProps & {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  options: any;
  optionsType: 'object' | 'array';
};

const SelectWrapper = ({
  name,
  options,
  optionsType,
  ...otherProps
}: SelectWrapperProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name as string);

  const handleChange = (
    evt: SelectChangeEvent & ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = evt.target;
    setFieldValue(name as string, value);
  };

  const configSelect: TextFieldProps = {
    ...field,
    ...otherProps,
    select: true,
    variant: 'outlined',
    color: 'secondary',
    fullWidth: true,
    onChange: handleChange
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField {...configSelect}>
      {optionsType === 'object'
        ? Object.keys(options).map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {options[item]}
              </MenuItem>
            );
          })
        : options.map(({ text, id }: { id: number; text: string }) => {
            return (
              <MenuItem key={id} value={id}>
                {text}
              </MenuItem>
            );
          })}
    </TextField>
  );
};

export default SelectWrapper;
