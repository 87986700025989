import React, { useState } from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import dobrotaLeci from '../../assets/images/dobrota-leci.png';
import AuthLayout from '../../components/layout/AuthLayout';
import TextInput from '../../components/form/TextInput';
import { useAuth } from '../../hooks/useAuth';
import useToastContext from '../../hooks/useToastContext';

const formValidation = Yup.object().shape({
  email: Yup.string()
    .email('Email nije validnog formata!')
    .required('Ovo polje je obavezno!')
});

export default function ForgotPassword() {
  localStorage.clear();
  const { sendPasswordResetEmail } = useAuth();
  const [showToast] = useToastContext();

  const { mutate, isLoading } = useMutation(async (email: string) =>
    sendPasswordResetEmail(email)
  );

  const handleFormikSubmit = (email: string, resetForm: () => void) => {
    mutate(email, {
      onError: () => {
        showToast({
          text: 'Došlo je do greške. Pokušajte opet kasnije!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showToast({
          text: 'Mail je uspešno poslat.',
          severity: 'success'
        });
        resetForm();
      }
    });
  };
  return (
    <AuthLayout>
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        <img src={dobrotaLeci} alt="logo" style={{ width: '200px' }} />
      </Box>
      <Box
        my={8}
        mx={4}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          component="h1"
          variant="h5"
          align="left"
          sx={{ alignSelf: 'flex-start' }}
        >
          Upišite e-mail
        </Typography>
        <Box component="div" sx={{ mt: 1 }}>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={formValidation}
            onSubmit={({ email }, { resetForm }) => {
              handleFormikSubmit(email, resetForm);
            }}
          >
            {({ isValid, dirty }) => (
              <Form>
                <TextInput
                  margin="normal"
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <LoadingButton
                  loading={isLoading}
                  disabled={dirty && !isValid}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  POŠALJI MEJL
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </AuthLayout>
  );
}
