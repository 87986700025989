import { FormGroup } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent } from 'react';

const CheckboxWrapper = ({
  name,
  label,
  rowStatus
}: CheckboxProps & { label: string; rowStatus?: string }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name as string);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFieldValue(name as string, checked ? 'APPROVED' : 'PENDING');
  };

  const configCheckbox = {
    ...field,
    onChange: handleChange
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            {...configCheckbox}
            defaultChecked={rowStatus === 'APPROVED' && true}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default CheckboxWrapper;
