import { useQuery } from 'react-query';
import { getInstitution } from '../api/institutions';

const useInstitution = (id: string, { ...props }) => {
  const responseQuery = useQuery(
    ['institution', id],
    () => {
      return getInstitution(id);
    },
    {
      ...props
    }
  );

  return { ...responseQuery };
};

export default useInstitution;
