import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import Paper from '@mui/material/Paper';
import leftBg from '../../assets/images/left_bg.png';

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 5 }}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://www.omega.vegait.rs/">
        VegaIt Omega
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const AuthLayout = ({ children }: any) => {
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={6}
        md={7}
        sx={{
          backgroundImage: `url(${leftBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        component={Paper}
        elevation={0}
        square
        flexDirection="column"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          p: { xs: 2, sm: 0, lg: 10, xl: 17 }
        }}
      >
        {children}
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
