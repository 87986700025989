import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField
} from '@mui/material';
import { Formik, Form } from 'formik';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { SyntheticEvent, useEffect, useState } from 'react';
import { postBooking } from '../../api/bookings';
import useToastContext from '../../hooks/useToastContext';
import { PostBookingDTO, Status } from '../../interfaces/SlotBookingsDTO';
import { useAuth } from '../../hooks/useAuth';
import { searchForDonors } from '../../api/users';
import useDebounce from '../../hooks/useDebounce';
import { DonorResponseDTO } from '../../interfaces/users/donorResponseDTO';
import { toIsoString } from '../../utils/utils';

interface AddBookingFormProps {
  open: boolean;
  handleClose: () => void;
  bookingData: {
    startAt: string;
    slotUUID: string;
  };
  selectedDate: Date;
}

const AddBookingForm = ({
  open,
  handleClose,
  bookingData,
  selectedDate
}: AddBookingFormProps) => {
  const queryClient = useQueryClient();

  const addBookingMutation = useMutation(async (newBooking: PostBookingDTO) =>
    postBooking(newBooking)
  );

  const [showToast] = useToastContext();

  const { user } = useAuth();
  const date = new Date(selectedDate);
  if (bookingData.startAt) {
    const [hours, minutes, seconds] = bookingData.startAt.split(':');
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(parseInt(seconds, 10));
  }

  const initialValues: PostBookingDTO = {
    donorUUID: '',
    note: '',
    slotUUID: bookingData.slotUUID,
    status: Status.confirmed,
    userConfirmedByUUID: user ? user.id : '',
    startAt: toIsoString(date)
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDonorId, setSelectedDonorId] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleSelectDonorChange = (
    event: SyntheticEvent,
    newValue: DonorResponseDTO | null
  ) => {
    if (newValue && newValue.id) {
      setSearchTerm('');
      setSelectedDonorId(newValue.id);
    } else {
      setSelectedDonorId('');
    }
  };

  const handleSelectDonorBlur = () => {
    if (!selectedDonorId) setSearchTerm('');
  };

  const {
    data: donors,
    isLoading: isDonorsLoading,
    refetch
  } = useQuery<DonorResponseDTO[]>(
    'queriedDonors',
    () => {
      return searchForDonors(
        debouncedSearchTerm,
        selectedDate.toLocaleDateString('es-CL')
      );
    },
    {
      enabled: debouncedSearchTerm === ''
    }
  );

  useEffect(() => {
    if (debouncedSearchTerm || selectedDate) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, selectedDate]);

  const handleSubmit = (values: PostBookingDTO) => {
    if (!selectedDonorId) {
      showToast({
        text: 'Korsnik nije validan, pokušajte opet!',
        severity: 'error'
      });
      return;
    }
    addBookingMutation.mutate(values, {
      onError: () => {
        showToast({
          text: 'Došlo je do greške. Pokušajte opet kasnije!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries('bookings').then(() => {});
        showToast({
          text: 'Termin je uspješno zakazan.',
          severity: 'success'
        });
        setSelectedDonorId('');
        setSearchTerm('');
      }
    });
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { height: '70%' } }}
        fullWidth
      >
        <DialogTitle>Zakaži termin</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ ...initialValues }}
            onSubmit={(values) => {
              handleSubmit({ ...values, donorUUID: selectedDonorId });
            }}
          >
            {({ dirty }) => (
              <Form>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={{ height: '50%' }}
                      disablePortal
                      id="donors-combo-box"
                      options={donors || []}
                      getOptionLabel={(donor: DonorResponseDTO) =>
                        `${donor.firstName} ${donor.lastName}`
                      }
                      renderOption={(props, donor) => {
                        return (
                          <li {...props} key={donor.id}>
                            {donor.firstName} {donor.lastName}
                          </li>
                        );
                      }}
                      loading={isDonorsLoading}
                      onChange={handleSelectDonorChange}
                      onBlur={handleSelectDonorBlur}
                      renderInput={(params) => (
                        <TextField
                          onClick={() => refetch()}
                          type="text"
                          label="Pretraži donora"
                          value={searchTerm}
                          {...params}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      )}
                    />
                  </Grid>

                  <Box
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    gap={2}
                  >
                    <Button onClick={handleClose}>Otkaži</Button>
                    <Button
                      variant="contained"
                      disabled={!dirty && selectedDonorId === ''}
                      type="submit"
                    >
                      Potvrdi
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddBookingForm;
