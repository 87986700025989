import { AxiosResponse } from 'axios';
import { PostBookingDTO, SlotBookingsDTO } from '../interfaces/SlotBookingsDTO';
import api from '../utils/api';

function formatDate(date: Date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export const getSlotsForDate = async ({
  selectedDate,
  institutionId
}: {
  selectedDate: Date;
  institutionId: string;
}) => {
  const formattedDate = formatDate(selectedDate);
  try {
    // 'https://mocki.io/v1/d7ee6a5f-351e-4fcd-995c-3ce7e705634f'

    const response: AxiosResponse<SlotBookingsDTO> = await api.get(
      `slot?institutionId=${institutionId}&date=${formattedDate}`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `${error}` || 'Something went wrong while fetching bookings'
    );
  }
};

export const postBooking = (booking: PostBookingDTO) => {
  try {
    return api.post('/booking', booking);
  } catch (error) {
    return error;
  }
};

export const confirmBooking = (bookingId: string) => {
  try {
    return api.put(`/booking/${bookingId}/confirm`);
  } catch (error) {
    return error;
  }
};

export const cancelBooking = (bookingId: string) => {
  try {
    return api.put(`/booking/${bookingId}/cancel`);
  } catch (error) {
    return error;
  }
};

export const finishBooking = (bookingId: string) => {
  try {
    return api.put(`/booking/${bookingId}/finish`);
  } catch (error) {
    return error;
  }
};
