import React, { FC, useState } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Grid
} from '@mui/material';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { useMutation, useQueryClient } from 'react-query';
import styles from './Bookings.module.css';

import { BookingDTO } from '../../../interfaces/SlotBookingsDTO';
import {
  confirmBooking,
  cancelBooking,
  finishBooking
} from '../../../api/bookings';
import useToastContext from '../../../hooks/useToastContext';
import ConfirmDialog from '../../dialogs/ConfirmDialog';

interface BookingItemProps {
  booking: BookingDTO;
}

const BookingItem: FC<BookingItemProps> = ({ booking }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { donor } = booking;
  const [showToast] = useToastContext();
  const queryClient = useQueryClient();
  const [openDialog, setOpenDialog] = useState(false);
  const closeDialog = () => setOpenDialog(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const getDayName = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('sr-latn-SR', { weekday: 'long' });
  };

  const getMonthName = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('sr-latn-SR', { month: 'long' });
  };

  const getHours = (dateStr: string) => {
    const date = new Date(dateStr);
    if (date.getHours() >= 0 && date.getHours() < 10) {
      return `0${date.getHours()}`;
    }
    return date.getHours();
  };

  const getMinutes = (dateStr: string) => {
    const date = new Date(dateStr);
    if (date.getMinutes() < 10) {
      return `0${date.getMinutes()}`;
    }
    return date.getMinutes();
  };

  const getHumanReadableDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return `${getDayName(dateStr)} ${date.getDate()}. ${getMonthName(
      dateStr
    )} u ${getHours(dateStr)} : ${getMinutes(dateStr)} časova`;
  };

  const { mutate: confirmBookingMutation } = useMutation(
    async (bookingId: string) => confirmBooking(bookingId)
  );

  const { mutate: cancelBookingMutation } = useMutation(
    async (bookingId: string) => cancelBooking(bookingId)
  );

  const { mutate: finishBookingMutation } = useMutation(
    async (bookingId: string) => finishBooking(bookingId)
  );
  const handleBookingConfirm = () => {
    confirmBookingMutation(booking.id, {
      onError: () => {
        showToast({
          text: 'Došlo je do greške. Pokušajte opet kasnije!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries('bookings').then(() => {});
        showToast({
          text: 'Termin je uspješno potvrdjen i zakazan.',
          severity: 'success'
        });
        handleClose();
      }
    });
  };

  const handleBookingCancel = () => {
    cancelBookingMutation(booking.id, {
      onError: () => {
        showToast({
          text: 'Došlo je do greške. Pokušajte opet kasnije!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries('bookings').then(() => {});
        showToast({
          text: 'Termin je uspješno otkazan.',
          severity: 'success'
        });
        handleClose();
      }
    });
  };

  const handleBookingFinish = () => {
    finishBookingMutation(booking.id, {
      onError: () => {
        showToast({
          text: 'Došlo je do greške. Pokušajte opet kasnije!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries('bookings').then(() => {});
        showToast({
          text: 'Termin je uspješno završen.',
          severity: 'success'
        });
        handleClose();
      }
    });
  };

  return (
    <div>
      <Card
        sx={{ minWidth: 275 }}
        className={classNames(`${styles.bookingCard}`, {
          [styles.bookingApproved]: booking.status === 'CONFIRMED',
          [styles.bookingPending]: booking.status === 'PENDING',
          [styles.bookingRejected]: booking.status === 'CANCELED',
          [styles.bookingFinished]: booking.status === 'FINISHED'
        })}
      >
        <CardActionArea onClick={handleOpen} sx={{ padding: '1.05rem 1.5rem' }}>
          <Typography
            sx={{ fontSize: 14, fontWeight: 500 }}
            color="primary"
            gutterBottom
          >
            {`${donor.firstName} ${donor.lastName}`}, grupa{' '}
            {donor.bloodType || 'nepoznata'}
            <span>{donor.telephoneNumber || 'nepoznat broj telefona'}</span>
          </Typography>
        </CardActionArea>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle className={styles.dialogTitle}>
          DETALJI TERMINA
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            className={styles.bookingDate}
            variant="h6"
            component="h6"
          >
            {getHumanReadableDate(booking.startAt)}
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={6}>
              <Box className={styles.bookingInfo}>
                <Typography variant="h6" component="h6">
                  Ime Prezime
                </Typography>
                <Typography component="p">
                  {donor.firstName} {donor.lastName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={styles.bookingInfo}>
                <Typography variant="h6" component="h6">
                  Krvna grupa
                </Typography>
                <Typography component="p">
                  {donor.bloodType || 'nepoznato'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={styles.bookingInfo}>
                <Typography variant="h6" component="h6">
                  Mesto prebivališta
                </Typography>
                <Typography component="p">{donor.city}</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <ConfirmDialog
          dialogTitle="Otkazivanje termina"
          dialogDescription="Da li ste sigurni da želite da otkažete termin "
          name=""
          openDialog={openDialog}
          handleClose={closeDialog}
          handleDelete={handleBookingCancel}
        />
        <DialogActions>
          {(booking.status === 'PENDING' || booking.status === 'CONFIRMED') && (
            <Button color="warning" onClick={() => setOpenDialog(true)}>
              OTKAŽI TERMIN
            </Button>
          )}
          {booking.status === 'PENDING' && (
            <Button
              variant="contained"
              onClick={handleBookingConfirm}
              autoFocus
            >
              POTVRDI
            </Button>
          )}
          {booking.status === 'CONFIRMED' && (
            <Button variant="contained" onClick={handleBookingFinish} autoFocus>
              ZAVRŠI
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BookingItem;
