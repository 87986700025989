/* eslint-disable react/no-array-index-key */
import { ChangeEvent, FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Pagination, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { UserDTO } from '../../../interfaces/users/userDTO';
import { OrderOptions, SortOptions } from '../../../utils/constants';

interface TableHeaders {
  text: string;
  align: 'left' | 'right' | 'center';
}

const HEADERS: TableHeaders[] = [
  {
    text: 'Ime i prezime',
    align: 'left'
  },
  {
    text: 'Krvna grupa',
    align: 'left'
  },
  {
    text: 'Datum rodjenja',
    align: 'left'
  },
  {
    text: 'E-mail',
    align: 'left'
  },
  {
    text: 'Prebivališe ',
    align: 'left'
  },
  {
    text: 'Termini donacije',
    align: 'left'
  },
  {
    text: '',
    align: 'left'
  }
];

interface UsersTableProps {
  handleDialogOpen: (act: string, user?: UserDTO) => void;
  users: UserDTO[] | undefined;
  totalPages: number | undefined;
  page: number;
  onPageChange: (page: number) => void;
  sortBy: SortOptions | undefined;
  orderBy: OrderOptions | undefined;
  setSortBy: unknown;
  setOrderBy: unknown;
}

const UsersTable: FC<UsersTableProps> = ({
  handleDialogOpen,
  users,
  page,
  totalPages = 0,
  onPageChange,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  sortBy,
  orderBy,
  setSortBy,
  setOrderBy
  /* eslint-enable  @typescript-eslint/no-unused-vars */
}) => {
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      pb={2}
      mb={5}
    >
      <TableContainer
        component={Paper}
        elevation={0}
        square
        sx={{ mt: 3, pb: 3, borderTop: '1px solid #F4F4F4' }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {HEADERS.map(({ align, text }) => (
                <TableCell key={text} align={align}>
                  {text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row, index) => (
              <TableRow
                key={index}
                sx={
                  row.status !== 'APPROVED'
                    ? { '&:hover .seeUser': { opacity: 1 } }
                    : {
                        '&:hover .seeUser': { opacity: 1 },
                        backgroundColor: '#EAFCFC'
                      }
                }
              >
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body1"
                    sx={
                      row.status !== 'APPROVED'
                        ? { color: 'primary.main', fontWeight: 700 }
                        : { color: 'textColor.dark' }
                    }
                  >
                    {row.firstName} {row.lastName}
                  </Typography>
                </TableCell>
                <TableCell>
                  {row.bloodType && (
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'textColor.main' }}
                    >
                      {row.bloodType}
                      {row.rhFactor === 'POSITIVE' ? '+' : '-'}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ color: 'textColor.light' }}>
                    {row.dateOfBirth}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ color: 'textColor.dark' }}>
                    {row.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{row.city}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" sx={{ color: 'textColor.dark' }}>
                    {row.latestBookingTime}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {row.status === 'PENDING' || row.status === 'UNKNOWN' ? (
                    <Box
                      display="flex"
                      gap={1}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleDialogOpen('VERIFY', row)}
                    >
                      <Typography
                        variant="body2"
                        color="primary"
                        className="seeUser"
                        sx={{ opacity: 0 }}
                      >
                        Verifikacija korisnika
                      </Typography>
                      <HelpOutlineOutlinedIcon
                        className="seeUser"
                        sx={{ opacity: 0.8 }}
                        color="primary"
                      />
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      gap={1}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleDialogOpen('EDIT', row)}
                    >
                      <Typography
                        variant="body2"
                        color="primary"
                        className="seeUser"
                        sx={{ opacity: 0 }}
                      >
                        Pogledajte korisnika
                      </Typography>
                      <VisibilityIcon
                        className="seeUser"
                        sx={{ opacity: 0.8 }}
                        color="primary"
                      />
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        color="primary"
        count={totalPages}
        page={page}
        onChange={handleChange}
      />
    </Box>
  );
};

export default UsersTable;
