import { UserDTO } from './users/userDTO';

export interface DonorDTO {
  firstName: string;
  lastName: string;
  city: string;
  bloodType: string;
}

export enum Status {
  confirmed = 'CONFIRMED',
  pending = 'PENDING',
  canceled = 'CANCELED',
  finished = 'FINISHED'
}

export interface BookingDTO {
  id: string;
  startAt: string;
  status: Status;
  note: string;
  donor: UserDTO;
}

export interface ISlotPreview {
  id: string;
  time: string;
}

export interface SlotBookingDTO {
  slot: ISlotPreview;
  bookings: BookingDTO[];
}

export interface PostBookingDTO {
  donorUUID: string;
  note?: string;
  slotUUID: string;
  startAt: string;
  status: Status;
  userConfirmedByUUID?: string;
}

export type SlotBookingsDTO = Array<SlotBookingDTO>;
