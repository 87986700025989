import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MobileTimePicker } from '@mui/lab';
import { Button, Divider, Stack, TextField } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import AddInstitutionUserForm from '../dialogs/AddInstitutionUserForm';
import AddButton from './AddButton';
import ApprovedUsersTable from './ApprovedUsersTable';
import ScheduleList from './ScheduleList';
import { InstitutionResponseDto } from '../../interfaces/intitutions/institutionResponseDto';
import { AddSlotAttributeDto } from '../../interfaces/addSlotAttributeDto';
import { addSlotToInstitution } from '../../api/slots';
import { deleteInstitution } from '../../api/institutions';
import AddEditInstitutionForm from '../dialogs/AddEditInstitutionForm';
import useToastContext from '../../hooks/useToastContext';
import { dateToHoursAndMinutes } from '../../utils/helpers';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import Acl from '../base/Acl';
import { Role } from '../../interfaces/users/userResponseDto';

const InstitutionsDashboard: React.FC<{
  institution: InstitutionResponseDto;
  setInstitutionId: (institutionId: string) => void;
}> = ({ institution, setInstitutionId }) => {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [deleteForm, setOpenDeleteForm] = useState(false);
  const handleEditFormOpen = () => setOpenEditForm(true);
  const handleEditFormClose = () => setOpenEditForm(false);
  const openDeleteForm = () => setOpenDeleteForm(true);
  const closeDeleteForm = () => setOpenDeleteForm(false);
  const hideMask = true;
  const [date, setDate] = useState<Date | null>(new Date());
  const queryClient = useQueryClient();
  const [showToast] = useToastContext();
  const addEditPermissions: Array<Role> = ['SUPER_ADMIN', 'INSTITUTION_ADMIN'];
  const regex = new RegExp(' /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/');
  const handleChange = (newDate: Date | null) => {
    setDate(newDate);
  };

  const [addUserModalOpen, setAddUserModal] = useState(false);

  const addSlot = useMutation<
    InstitutionResponseDto,
    Error,
    AddSlotAttributeDto
  >(async (slot) => addSlotToInstitution(slot));

  const deleteInstitutionMutation = useMutation<void, Error, string>(
    async (institutionId: string) => deleteInstitution(institutionId)
  );

  const handleDeleteInstitution = () => {
    deleteInstitutionMutation.mutate(institution.id, {
      onError: () => {
        showToast({
          text: 'Došlo je do greške prilikom brisanja Institucije. Pokušajte opet kasnije!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries('institutions').then();
        queryClient.invalidateQueries('institution').then();
        setInstitutionId('');

        showToast({
          text: 'Institucija uspešno obrisana.',
          severity: 'success'
        });
      }
    });
  };

  let timeString = '';

  if (date) {
    timeString = dateToHoursAndMinutes(date);
  }
  const handleAddSlot = () => {
    addSlot.mutate(
      { id: institution.id, time: { time: timeString } },
      {
        onError: () => {
          showToast({
            text: 'Došlo je do greške prilikom dodavanja Termina. Pokušajte opet kasnije!',
            severity: 'error'
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries('institution').then();

          showToast({
            text: 'Termin uspešno dodat.',
            severity: 'success'
          });
        }
      }
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 4,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Toolbar />
      <Box sx={{ my: 3 }}>
        <Typography variant="h5">{institution?.name}</Typography>
        <Typography align="center" variant="body1" sx={{ marginLeft: 'auto' }}>
          Br. separatora - {institution?.numberOfDevices}
        </Typography>
      </Box>
      <ApprovedUsersTable institutionUsers={institution.users} />
      <Box sx={{ alignSelf: 'flex-start' }}>
        <Acl userRole={addEditPermissions}>
          <AddButton
            buttonText="Dodaj ovlašćeno lice"
            onClick={() => {
              setAddUserModal(true);
            }}
          />
        </Acl>
        <AddInstitutionUserForm
          open={addUserModalOpen}
          institutionId={institution.id}
          handleClose={() => {
            setAddUserModal(false);
          }}
        />
      </Box>
      <Box sx={{ alignSelf: 'flex-start', mt: 2, width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography variant="h6" sx={{ my: 3 }}>
            Podešavanje Termina:
          </Typography>
          <Box sx={{ display: 'flex', mb: 4 }}>
            <MobileTimePicker
              ampm={false}
              okText="potvrdi"
              cancelText="otkaži"
              acceptRegex={regex}
              disableMaskedInput={hideMask}
              label="Vreme"
              value={date}
              onChange={handleChange}
              renderInput={(params) => {
                // eslint-disable-next-line react/jsx-props-no-spreading
                return <TextField {...params} />;
              }}
            />
            <Acl userRole={addEditPermissions}>
              <AddButton buttonText="Dodaj Termin" onClick={handleAddSlot} />
            </Acl>
          </Box>
          <Divider />
          <Box />
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ my: 3 }}>
              Dodati termini:
            </Typography>
            {institution && institution.slots ? (
              <ScheduleList institution={institution} />
            ) : (
              <div>No Slots</div>
            )}
          </Box>
        </LocalizationProvider>
      </Box>
      <Acl userRole={['SUPER_ADMIN']}>
        <Box sx={{ alignSelf: 'flex-start' }}>
          <Typography variant="h6" sx={{ my: 5 }}>
            Podešavanja Institucije:
          </Typography>
          <Stack spacing={2} direction="row">
            <Button variant="contained" onClick={handleEditFormOpen}>
              Izmeniti Instituciju
            </Button>
            <Button variant="contained" color="error" onClick={openDeleteForm}>
              Izbriši Instituciju
            </Button>
          </Stack>
        </Box>
      </Acl>
      <ConfirmDialog
        dialogTitle="Brisanje Institucije"
        dialogDescription="Da li ste sigurni da želite da izbrišete instituciju"
        name={institution.name}
        openDialog={deleteForm}
        handleClose={closeDeleteForm}
        handleDelete={handleDeleteInstitution}
      />
      <AddEditInstitutionForm
        open={openEditForm}
        handleClose={handleEditFormClose}
        setInstitutionId={setInstitutionId}
        type="edit"
        institutionProp={institution}
      />
    </Box>
  );
};

export default InstitutionsDashboard;
