import { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ListItemText from '@mui/material/ListItemText';
import { InstitutionResponseDto } from '../../interfaces/intitutions/institutionResponseDto';

interface InstitutionsListPops {
  institutionsData?: InstitutionResponseDto[];
  setInstitutionId: (institutionId: string) => void;
  institutionId: string;
  isLoading: boolean;
  isSuccess: boolean;
}

const InstitutionsList: FC<InstitutionsListPops> = ({
  institutionsData,
  setInstitutionId,
  institutionId,
  isLoading,
  isSuccess
}) => {
  return (
    <List>
      <ListItem key="Institucije">
        <Typography variant="h5">Institucije</Typography>
      </ListItem>
      <Divider />
      {isLoading && <div>Loading...</div>}
      {isSuccess &&
        institutionsData?.map((institution) => (
          <ListItem
            button
            onClick={() => setInstitutionId(institution.id)}
            key={institution.id}
            sx={{
              backgroundColor:
                institutionId === institution.id ? 'header.main' : '',
              borderBottom: '1px solid rgb(212, 212, 212)',
              '&:hover': {
                bgcolor: 'header.main'
              }
            }}
            secondaryAction={
              <IconButton edge="end" aria-label="manage-institution">
                <KeyboardArrowRightIcon style={{ color: '#7ed6d2' }} />
              </IconButton>
            }
          >
            <ListItemText primary={institution.name} />
          </ListItem>
        ))}
    </List>
  );
};

export default InstitutionsList;
