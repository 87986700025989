import axios from 'axios';
import { BACKEND_BASE_URL } from './config';

const jwtToken = localStorage.getItem('token');

const api = axios.create({
  baseURL: BACKEND_BASE_URL,
  headers: {
    Authorization: `Bearer ${jwtToken}`
  }
});

const UNAUTHORIZED = 401;

api.interceptors.request.use((request) => {
  const token = localStorage.getItem('token');

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
