import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

function AddButton({
  buttonText,
  onClick
}: {
  buttonText: string;
  onClick: () => void;
}) {
  return (
    <Button
      onClick={onClick}
      variant="text"
      sx={{
        m: 1
      }}
      startIcon={
        <AddIcon
          sx={{
            background: '#7ed6d2',
            borderRadius: '15px',
            color: 'white'
          }}
        />
      }
    >
      {buttonText}
    </Button>
  );
}

export default AddButton;
