import { List } from '@mui/material';
import { InstitutionResponseDto } from '../../interfaces/intitutions/institutionResponseDto';
import ScheduleSlot from './ScheduleSlot';

interface ScheduleListProps {
  institution: InstitutionResponseDto;
}

const ScheduleList = ({ institution }: ScheduleListProps) => {
  const timeSchedules = institution.slots;

  return (
    <div>
      <List
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))'
        }}
      >
        {timeSchedules.map((slot, index) => (
          <ScheduleSlot
            key={slot.id}
            slot={slot}
            index={index}
            institution={institution}
          />
        ))}
      </List>
    </div>
  );
};

export default ScheduleList;
