import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { sub } from 'date-fns';
import { DialogTitle, Grid, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { BLOOD_OPTIONS } from '../../utils/constants';
import TextInput from '../form/TextInput';
import Select from '../form/Select';
import SelectCityFormik from '../form/SelectCityFormik';
import DatePicker from '../form/DatePicker';
import Checkbox from '../form/Checkbox';
import { findBloodGroupId, parsePhoneNumber } from '../../utils/utils';
import { UserDTO } from '../../interfaces/users/userDTO';
import { UserResponseDto } from '../../interfaces/users/userResponseDto';
import { postUser, updateUser } from '../../api/users';
import useToastContext from '../../hooks/useToastContext';

interface MapObj {
  title: string;
  buttonText: string;
}
interface ActionTitleMap {
  ADD: MapObj;
  EDIT: MapObj;
  VERIFY: MapObj;
}

const MAP_ACTION_TITLE: ActionTitleMap = {
  ADD: { title: 'Dodaj Novog Korisnika', buttonText: 'Dodaj' },
  EDIT: { title: 'Pregled Korisnika', buttonText: 'Sačuvaj' },
  VERIFY: { title: 'Verifikacija Korisnika', buttonText: 'Odobri' }
};

const COUNTRY_CODE = '+381';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  telephoneNumber: '',
  role: 'DONOR',
  city: '',
  dateOfBirth: sub(new Date(), { years: 18 }),
  bloodType: '',
  note: '',
  status: 'PENDING',
  password: ''
};

const phoneRegExp = /^[6][0-9]{7,8}$/;

const FORM_VALIDATION = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Ime mora da sadrži minimum 2 karaktera')
    .required('Obavezno'),
  lastName: Yup.string()
    .min(2, 'Ime mora da sadrži minimum 2 karaktera')
    .required('Obavezno'),
  email: Yup.string().email('Nevalidna email adresa').required('Obavezno'),
  telephoneNumber: Yup.string()
    .matches(
      phoneRegExp,
      'Broj telefona mora da počne sa 6 i da sadrži 7 ili 8 cifara'
    )

    .required('Obavezno'),
  city: Yup.string().required('Obavezno').typeError('Obavezno'),
  dateOfBirth: Yup.date()
    .max(sub(new Date(), { years: 18 }))
    .required('Obavezno'),
  bloodType: Yup.string().required('Obavezno'),
  password: Yup.string()
    .min(8, 'Lozinka mora biti minimum 8 karaktera')
    .max(255, 'Lozinka moze biti maksimum 255 karaktera'),
  note: Yup.string()
});

const AddEditVerifyUser = ({
  open,
  handleClose,
  action,
  userObj
}: {
  open: boolean;
  handleClose: () => void;
  action: string;
  userObj?: UserDTO | null;
}) => {
  const queryClient = useQueryClient();

  const addUser: UseMutationResult<UserResponseDto, Error, UserDTO> =
    useMutation<UserResponseDto, Error, UserDTO>(async (user) => {
      return postUser(user);
    });
  const editUser: UseMutationResult<UserResponseDto, Error, UserDTO> =
    useMutation<UserResponseDto, Error, UserDTO>(async (user) => {
      return updateUser(user);
    });

  const [showToast] = useToastContext();
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ square: true }}>
      <DialogTitle>
        {MAP_ACTION_TITLE[action as 'ADD' | 'EDIT' | 'VERIFY']?.title}
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={
            action === 'ADD'
              ? {
                  ...INITIAL_FORM_STATE
                }
              : {
                  ...userObj,
                  bloodType: findBloodGroupId(
                    userObj?.bloodType,
                    userObj?.rhFactor
                  ),
                  telephoneNumber: parsePhoneNumber(userObj?.telephoneNumber),
                  note: userObj?.note || ''
                }
          }
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            const bloodType = BLOOD_OPTIONS.find(
              (el) => el.id === values.bloodType
            );
            const newUserValues = {
              ...values,
              ...bloodType?.value
            };
            const parsedValues = {
              ...values,
              ...bloodType?.value,
              telephoneNumber: `+381${values?.telephoneNumber}`
            };

            const postValues = newUserValues as unknown as UserResponseDto;
            const editValues = parsedValues as unknown as UserResponseDto;

            if (action === 'ADD') {
              addUser.mutate(postValues, {
                onError: () => {
                  showToast({
                    text: 'Došlo je do greške. Pokušajte opet kasnije!',
                    severity: 'error'
                  });
                },
                onSuccess: () => {
                  queryClient.invalidateQueries('users').then(() => {});
                  showToast({
                    text: 'Korisnik uspešno dodat.',
                    severity: 'success'
                  });
                  handleClose();
                  queryClient.refetchQueries();
                }
              });
            } else {
              editUser.mutate(editValues, {
                onError: () => {
                  showToast({
                    text: 'Došlo je do greške. Pokušajte opet kasnije!',
                    severity: 'error'
                  });
                },
                onSuccess: () => {
                  queryClient.invalidateQueries('users').then(() => {});
                  showToast({
                    text: 'Korisnik uspešno izmenjen.',
                    severity: 'success'
                  });
                  handleClose();
                  queryClient.refetchQueries();
                }
              });
            }
          }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  <TextInput name="firstName" label="Ime" />
                </Grid>
                <Grid item xs={6}>
                  <TextInput name="lastName" label="Prezime" />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    name="bloodType"
                    label="Krvna grupa"
                    options={BLOOD_OPTIONS}
                    optionsType="array"
                    sx={{
                      '.MuiSelect-select': {
                        fontWeight: 700,
                        color: 'primary.main'
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatePicker
                    name="dateOfBirth"
                    label="Datum rodjenja"
                    maxDate={sub(new Date(), { years: 18 })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput name="email" label="Email" />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    name="telephoneNumber"
                    label="Broj telefona"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {COUNTRY_CODE}
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput name="password" label="Lozinka" />
                </Grid>
                <Grid item xs={12}>
                  <SelectCityFormik
                    name="city"
                    label="Mesto prebivališta"
                    city={userObj?.city}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    name="note"
                    label="Napomena"
                    multiline
                    maxRows={4}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Checkbox
                    name="status"
                    label="Obavljen pregled vena"
                    rowStatus={userObj?.status}
                  />
                </Grid>
              </Grid>
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="flex-end"
                gap={2}
              >
                <Button onClick={handleClose}>Otkaži</Button>
                <Button
                  variant="contained"
                  // onClick={handleClose}
                  disabled={!(dirty && isValid)}
                  type="submit"
                >
                  {
                    MAP_ACTION_TITLE[action as 'ADD' | 'EDIT' | 'VERIFY']
                      ?.buttonText
                  }
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditVerifyUser;
