import { cities } from './cities';

export const BLOOD_TYPES_ARR = ['A', 'B', 'O', 'AB'];
export const BLOOD_TYPES_OBJ = { A: 'A', B: 'B', AB: 'AB', O: 'O' };
export const RH_FACTOR_ARR = ['+', '-'];
// noinspection JSUnusedGlobalSymbols
export const RH_FACTOR_OBJ = { NEGATIVE: '-', POSITIVE: '+' };

const selectBloodOptions = () => {
  const select: {
    id: number;
    text: string;
    value: { bloodType: string; rhFactor: string };
  }[] = [{ id: 0, text: '--', value: { bloodType: '-', rhFactor: '-' } }];
  let count = 0;
  BLOOD_TYPES_ARR.forEach((bloodType) => {
    RH_FACTOR_ARR.forEach((rh) => {
      count += 1;
      select.push({
        id: count,
        text: bloodType + rh,
        value: { bloodType, rhFactor: rh === '+' ? 'POSITIVE' : 'NEGATIVE' }
      });
    });
  });
  return select;
};

export const BLOOD_OPTIONS = selectBloodOptions();

export const CITIES = cities;

export type SortOptions =
  | 'BLOOD_TYPE'
  | 'CITY'
  | 'DATE_OF_BIRTH'
  | 'EMAIL'
  | 'FIRST_NAME'
  | 'LAST_DONATION';

export type OrderOptions = 'ASC' | 'DESC';
export interface UrlParams {
  page: number;
  sortBy: SortOptions | undefined;
  orderBy: OrderOptions | undefined;
  filter: string;
  searchTerm: string;
}

export const instUserTypes = {
  DOCTOR: 'Doktor',
  MEDICAL_WORKER: 'Medicinski radnik',
  OTHER: 'Ostalo'
};
