import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { Box } from '@mui/system';

import { BLOOD_TYPES_OBJ } from '../../utils/constants';
import SelectCity from '../form/SelectCity';

const SELECT_OPTIONS = { '--': '--', ...BLOOD_TYPES_OBJ };

const FilterUsersDialog = ({
  open,
  handleClose,
  handleFilterSubmit
}: {
  open: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  handleClose: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  handleFilterSubmit: any;
}) => {
  const [approved, setApproved] = useState(true);
  const [pending, setPending] = useState(true);
  const [city, setCity] = useState('');
  const [bloodType, setBloodType] = useState('');

  const handleApprovedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApproved(event.target.checked);
  };
  const handlePendingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPending(event.target.checked);
  };

  const handleClearFilters = () => {
    setApproved(true);
    setPending(true);
    setCity('');
    setBloodType('');
    handleFilterSubmit('');
  };

  const prepareStatusesForQuery = () => {
    const resultArray = [];
    if (approved) {
      resultArray.push('APPROVED');
    }
    if (pending) {
      resultArray.push('PENDING', 'UNKNOWN');
    }
    return resultArray.toString();
  };

  const prepareSearchParams = () => {
    const searchParams = new URLSearchParams();
    const statuses = prepareStatusesForQuery();
    if (statuses) {
      searchParams.append('statuses', statuses);
    }
    if (city) {
      searchParams.append('city', city);
    }
    if (bloodType && bloodType !== SELECT_OPTIONS['--']) {
      searchParams.append('bloodType', bloodType);
    }
    return searchParams.toString();
  };
  const handleChange = (event: SelectChangeEvent) => {
    setBloodType(event.target.value as string);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ square: true }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Prikaži</DialogTitle>

      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={pending} onChange={handlePendingChange} />
            }
            label="Novi korisnici (nije odrađen pregled vena)"
          />
          <FormControlLabel
            control={
              <Checkbox checked={approved} onChange={handleApprovedChange} />
            }
            label="Aktivni korisnici (odrađen pregled vena)"
          />
        </FormGroup>
      </DialogContent>
      <Divider />
      <DialogTitle>Dodaj filter</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <SelectCity
            name="city"
            label="Mesto prebivališta"
            handler={setCity}
            value={city}
          />
        </Box>
        <FormControl fullWidth>
          <InputLabel id="blood-type-select-label">Krvna grupa</InputLabel>
          <Select
            labelId="blood-type-select-label"
            id="blood-type-select"
            value={bloodType}
            label="Krvna grupa"
            onChange={handleChange}
          >
            {Object.keys(SELECT_OPTIONS).map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  {SELECT_OPTIONS[item as 'A' | 'B' | 'AB' | 'O']}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Odustani</Button>
        <Button onClick={handleClearFilters}>Počisti filtere</Button>
        <Button
          type="submit"
          variant="contained"
          onClick={() => handleFilterSubmit(prepareSearchParams())}
        >
          Potvrdi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterUsersDialog;
