import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import styles from './Bookings.module.css';

import { getSlotsForDate } from '../../../api/bookings';
import AddBookingForm from '../../dialogs/AddBookingForm';
import BookingSlot from './BookingSlot';
import { ISlotPreview } from '../../../interfaces/SlotBookingsDTO';

const Bookings = ({
  selectedDate,
  institutionId,
  numberOfDevices
}: {
  selectedDate: Date;
  institutionId: string;
  numberOfDevices: number;
}) => {
  const { data, isLoading, refetch } = useQuery(
    ['bookings', institutionId],
    () => {
      return getSlotsForDate({ selectedDate, institutionId });
    },
    {
      keepPreviousData: true,
      enabled: institutionId !== ''
    }
  );

  useEffect(() => {
    if (institutionId !== '') refetch();
  }, [refetch, selectedDate, institutionId]);

  const [isBookingFormOpen, setBookingFormOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({} as ISlotPreview);

  const openBookingForm = (slot: ISlotPreview) => {
    setSelectedSlot(slot);
    setBookingFormOpen(true);
  };

  const closeBookingForm = () => {
    setBookingFormOpen(false);
  };

  return (
    <div className={styles['bookings-container']}>
      <Box className={styles.grid}>
        {isLoading && <h1>Loading...</h1>}
        {data && data.length !== 0
          ? data.map((s) => (
              <BookingSlot
                key={s.slot.id}
                slot={s}
                numberOfDevices={numberOfDevices}
                openBookingForm={openBookingForm}
              />
            ))
          : 'Trenutno nema zakazanih termina.'}
      </Box>
      <AddBookingForm
        open={isBookingFormOpen}
        handleClose={closeBookingForm}
        selectedDate={selectedDate}
        bookingData={{ startAt: selectedSlot.time, slotUUID: selectedSlot.id }}
      />
    </div>
  );
};

export default Bookings;
