// noinspection JSUnusedGlobalSymbols

import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    header: Palette['primary'];
  }
  interface PaletteOptions {
    header: PaletteOptions['primary'];
  }

  interface Palette {
    textColor: Palette['primary'];
  }
  interface PaletteOptions {
    textColor: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ['Nunito', 'Work Sans', 'sans-serif'].join(','),
    body1: {
      fontFamily: 'Work Sans',
      fontSize: '14px',
      fontWeight: 300
    },
    body2: {
      fontWeight: 600
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 300
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 700
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
      fontFamily: 'Work Sans'
    }
  },
  palette: {
    primary: {
      light: '#b1ffff',
      main: '#7ed6d2',
      dark: '#4ba4a1',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#fffc8d',
      main: '#ffc95d',
      dark: '#c9992c',
      contrastText: '#ffffff'
    },
    header: {
      main: '#EAFCFC'
    },
    textColor: {
      dark: '#4F4F4F',
      main: '#6FBEBB',
      light: '#9F9F9F'
    }
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            padding: '16px'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Work Sans',
          fontWeight: 500
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '16px',
          fontWeight: 300,
          '&.Mui-selected': {
            color: '#4F4F4F'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '4px'
        }
      }
    }
  }
});

export default theme;
