import { ReactChild, ReactChildren } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Role } from '../../interfaces/users/userResponseDto';

interface AclProps {
  userRole: Role[];
  children: ReactChild | ReactChildren;
}

const Acl = (props: AclProps) => {
  const { children, userRole } = props;
  const { user } = useAuth();
  if (user) {
    return <>{userRole.includes(user.role) ? children : <div />}</>;
  }
  return <div />;
};

export default Acl;
