import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export interface IToastParams {
  text: string;
  severity: 'error' | 'warning' | 'success' | 'info';
  duration?: number;
  onClose?: () => void;
}

interface IAppToastProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  params: IToastParams;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AppToast = ({ open, setOpen, params }: IAppToastProps) => {
  const { text, severity } = params;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default AppToast;
